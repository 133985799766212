import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import { PATH_PAGE } from '../../routes/paths';
import Logo from '../../components/Logo';
import { email } from 'src/utils/getBaseInfo';
import useAddress from 'src/hooks/useAddress';
import { useTranslation } from 'react-i18next';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

interface Links {
  name: string;
  href: string;
  isExternal?: boolean;
}

interface Sections {
  headline: string;
  children: Links[];
}

export default function MainFooter() {
  const { address, link } = useAddress();
  const { t } = useTranslation(['common', 'footer']);

  const LINKS: Sections[] = [
    {
      headline: t('common:name'),
      children: [
        { name: t('footer:contactUs'), href: PATH_PAGE.contact },
        { name: t('footer:faq'), href: PATH_PAGE.faqs },
      ],
    },
    {
      headline: t('footer:legal'),
      children: [{ name: t('footer:privacyPolicy'), href: PATH_PAGE.privacy }],
    },
    {
      headline: t('footer:contact'),
      children: [
        { name: email, href: `mailto:${email}`, isExternal: true },
        { name: address, href: link, isExternal: true },
      ],
    },
  ];
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {t('common:mission')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      href={link.href}
                      target={!!link?.isExternal ? '_blank' : '_self'}
                      rel="noopener"
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2022. {t('footer:allRightsReserved')}
        </Typography>
      </Container>
    </RootStyle>
  );
}
