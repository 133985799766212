// routes
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import appIcons from '../../appIcons';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export const ICON_SIZE = {
  width: 22,
  height: 22,
};

const useNavMenu = () => {
  const isMobile = useResponsive('only', 'xs');
  const { t } = useTranslation(['publicNavbar']);

  const navMenu = [
    {
      title: t('publicNavbar:home'),
      icon: <Iconify icon={appIcons.home} {...ICON_SIZE} />,
      path: '/',
    },
    {
      title: t('publicNavbar:inventory'),
      icon: <Iconify icon={appIcons.listSolid} {...ICON_SIZE} />,
      path: PATH_PAGE.inventory,
    },
    {
      title: t('publicNavbar:reservation'),
      icon: <Iconify icon={appIcons.calendar} {...ICON_SIZE} />,
      path: PATH_PAGE.reservation,
    },
    {
      title: t('publicNavbar:contact'),
      icon: <Iconify icon={appIcons.infoCard} {...ICON_SIZE} />,
      path: PATH_PAGE.contact,
    },

    {
      title: t('publicNavbar:faq'),
      icon: <Iconify icon={appIcons.faq} {...ICON_SIZE} />,
      path: PATH_PAGE.faqs,
    },
    {
      title: t('publicNavbar:login'),
      icon: <Iconify icon={appIcons.login} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
    },
  ];

  isMobile &&
    navMenu.splice(2, 0, {
      title: 'Shop',
      icon: <Iconify icon={appIcons.shoppingCart} {...ICON_SIZE} />,
      path: PATH_PAGE.shop,
    });

  return navMenu;
};

export default useNavMenu;
