// ----------------------------------------------------------------------

export function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/login',
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  inventory: '/inventory',
  reservation: '/reservation',
  shop: '/shop',
  reserve: '/reserve',
  checkoutSuccess: '/checkout-success',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  privacy: '/privacy-policy',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
  },
  calendar: {
    root: path(ROOTS_DASHBOARD, '/calendar'),
    event: path(ROOTS_DASHBOARD, '/calendar/event'),
    new: path(ROOTS_DASHBOARD, '/calendar/event/new'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: (userId: string) => path(ROOTS_DASHBOARD, `/user/${userId}/edit`),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transaction'),
    list: path(ROOTS_DASHBOARD, '/transaction/list'),
    new: path(ROOTS_DASHBOARD, '/transaction/new'),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    list: path(ROOTS_DASHBOARD, '/inventory/list'),
    new: path(ROOTS_DASHBOARD, '/inventory/new'),
  },
  reservation: {
    root: path(ROOTS_DASHBOARD, '/reservation'),
  },
};
