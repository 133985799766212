const storageKeys = {
  accessToken: 'accessToken',
  cart: 'cart',
  checkoutInfo: 'checkoutInfo',
  settings: 'settings',
  shopper: 'shopper',
  timer: 'timer',
};

export default storageKeys;
