import { capitalCase } from 'change-case';
import { styled } from '@mui/material/styles';
import { Box, Stack, Tooltip, Container, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/Page';
import Image from '../../components/Image';
import Logo from '../../assets/compressed-logo.png';
import { useTranslation } from 'react-i18next';
import LoginForm from './components/LoginForm';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { t } = useTranslation(['common', 'login']);
  const { method } = useAuth();

  return (
    <Page title={t('login:tabTitle')}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t('login:title', { name: t('common:name') })}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{t('login:description')}</Typography>
              </Box>

              <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image disabledEffect src={Logo} sx={{ width: 50, height: 50 }} />
                </>
              </Tooltip>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
