import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, IconButton, InputAdornment, Typography, Grid, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import appIcons from '../../../appIcons';
import { PATH_PAGE } from 'src/routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

type FormValuesProps = {
  username: string;
  password: string;
  afterSubmit?: string;
};

const defaultValues = {
  username: '',
  password: '',
};

export default function LoginForm() {
  const { t } = useTranslation(['login']);
  const { login } = useAuth();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(t('login:validation.username')),
    password: Yup.string().required(t('login:validation.password')),
  });

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.username, data.password);
      reset();
    } catch (error) {
      console.error(error);

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ marginBottom: '2rem' }}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField
          name="username"
          label={t('login:username')}
          inputProps={{
            autoCapitalize: 'none',
          }}
        />

        <RHFTextField
          name="password"
          label={t('login:password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? appIcons.eye : appIcons.eyeOff} />
                </IconButton>
              </InputAdornment>
            ),
            autoCapitalize: 'none',
          }}
        />
      </Stack>
      <Grid sx={{ mt: -3, mb: 3 }}>
        <Typography variant="body2">
          <b>{t('login:adminOnlySignin')}</b>
        </Typography>
        <Typography variant="body2" sx={{ my: 1 }}>
          <Trans
            i18nKey="login:ifTeacher"
            components={{
              LinkComponent: (
                <Link component={RouterLink} to={PATH_PAGE.reservation} color="primary" />
              ),
              BoldComponent: <b />,
            }}
          />
        </Typography>
      </Grid>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {t('login:login')}
      </LoadingButton>
    </FormProvider>
  );
}
