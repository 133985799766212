// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ShoppingProvider } from './contexts/ShoppingContext';
import { Suspense } from 'react';

// ----------------------------------------------------------------------
const queryClient = new QueryClient();

export default function App() {
  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ThemeColorPresets>
            <RtlLayout>
              <NotistackProvider>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <ScrollToTop />
                  <ShoppingProvider>
                    <Router />
                  </ShoppingProvider>
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </ThemeColorPresets>
        </ThemeProvider>
      </QueryClientProvider>
    </Suspense>
  );
}
