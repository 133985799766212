import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/admin') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Admin Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <GeneralApp /> },
        { path: 'settings', element: <Settings /> },
        { path: 'reservation', element: <ReservationList /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/profile" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':userId/edit', element: <UserCreate /> },
          ],
        },
        {
          path: 'transaction',
          children: [
            { element: <Navigate to="/admin/transaction/list" replace />, index: true },
            { path: 'list', element: <TransactionList /> },
            { path: ':id', element: <ModifyTransaction /> },
            { path: 'new', element: <ModifyTransaction /> },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/admin/inventory/list" replace />, index: true },
            { path: 'list', element: <InventoryList /> },
            { path: ':id', element: <ModifyInventory /> },
            { path: 'new', element: <ModifyInventory /> },
          ],
        },
        {
          path: 'calendar',
          children: [
            { element: <Calendar />, index: true },
            { path: 'event/new', element: <ModifyEvent /> },
            { path: 'event/:id', element: <ModifyEvent /> },
          ],
        },
      ],
    },

    // Public Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'inventory', element: <Inventory /> },
        { path: 'reservation', element: <Reservation /> },
        { path: 'shop', element: <Shopping /> },
        { path: 'reserve/:eventId', element: <ReserveSpot /> },
        { path: 'user-reservations/:id', element: <ReservationInfo /> },
        { path: 'checkout-success', element: <CheckoutSuccess /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/login')));

const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/admin/dashboard')));
const Settings = Loadable(lazy(() => import('../pages/admin/settings')));
const ReservationList = Loadable(lazy(() => import('../pages/admin/reservationList')));

// TRANSACTIONS
const TransactionList = Loadable(lazy(() => import('../pages/admin/transactionList')));
const ModifyTransaction = Loadable(lazy(() => import('../pages/admin/modifyTransaction')));

// INVENTORY
const InventoryList = Loadable(lazy(() => import('../pages/admin/inventory')));
const ModifyInventory = Loadable(lazy(() => import('../pages/admin/modifyItem')));

// USER
const UserList = Loadable(lazy(() => import('../pages/admin/userList')));
const UserCreate = Loadable(lazy(() => import('../pages/admin/modifyUser')));

// APP
const Calendar = Loadable(lazy(() => import('../pages/admin/calendar')));
const ModifyEvent = Loadable(lazy(() => import('../pages/admin/modifyEvent')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/public/home')));
const Contact = Loadable(lazy(() => import('../pages/public/contact')));
const Reservation = Loadable(lazy(() => import('../pages/public/reservation')));
const Inventory = Loadable(lazy(() => import('../pages/public/inventory')));
const Shopping = Loadable(lazy(() => import('../pages/public/shopping')));
const ReserveSpot = Loadable(lazy(() => import('../pages/public/reserveSpot')));
const ReservationInfo = Loadable(lazy(() => import('../pages/public/reservationInfo')));
const CheckoutSuccess = Loadable(lazy(() => import('../pages/public/checkoutSuccess')));
const Faqs = Loadable(lazy(() => import('../pages/public/faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
