import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { latlng } from 'src/utils/getBaseInfo';

const useAddress = () => {
  const [link, setLink] = useState('');
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.userAgent.includes('iPhone')
    )
      setLink(`maps://maps.google.com/maps?daddr=${latlng.lat},${latlng.lng}&amp;ll=`);
    else {
      setLink(
        `https://www.google.com/maps/dir//${latlng.lat},${latlng.lng}/@${latlng.lat},${latlng.lng},12z`
      );
    }
  }, []);

  return { link, address: t('common:address') };
};

export default useAddress;
